import { useEffect, useState } from "react"

import { IPage, PaywallType } from "data/contentData/api/content"
import { useGetOrganisation } from "data/customerManager/hooks/organisation.hooks"
import isAuthorized from "lib/auth"

import useUser from "./useUser"

type AuthType = "user" | "organisation" | "googlebot" | "none"

/**
 *
 * Returns
 * authType: "user" | "organisation" | "googlebot" | "none"
 * authorized: true | false
 * paywallType: "no-subscription" | "not-logged-in" | "none"
 *
 * @param page
 * @returns
 */
export default function useGetAccessInfo(page: IPage) {
    const [authType, setAuthType] = useState<AuthType>("none")
    const [authorized, setAuthorized] = useState(false)
    const [paywallType, setPaywallType] = useState<PaywallType>("none")

    const isGoogle =
        typeof window === "undefined"
            ? false
            : navigator.userAgent.toLowerCase().includes("googlebot")

    const organisation = useGetOrganisation()

    const user = useUser()

    const isLoading = user.isLoading || organisation.isLoading

    useEffect(() => {
        if (isLoading) {
            return
        }

        const _authType: AuthType = getAuthType(
            isGoogle,
            organisation.authenticated,
            user.authenticated
        )

        setAuthType(_authType)
    }, [user, organisation, isGoogle, isLoading])

    useEffect(() => {
        if (isLoading) {
            return
        }

        const _authorized =
            isAuthorized(user.user?.roles as string[], page?.hasReadAccess) ||
            isAuthorized(organisation.roles, page?.hasReadAccess)

        setAuthorized(_authorized)
    }, [user, organisation, page, isLoading])

    useEffect(() => {
        if (isLoading) {
            return
        }

        const _paywallType = getPaywallType(authType, authorized)
        setPaywallType(_paywallType)
    }, [authType, authorized, isLoading])

    return { authType, authorized, paywallType, accessToken: user.accessToken }
}

function getAuthType(
    isGoogle: boolean,
    organisationIsAuthenticated: boolean,
    userIsAuthenticated: boolean
): AuthType {
    if (userIsAuthenticated) {
        return "user"
    }

    if (organisationIsAuthenticated) {
        return "organisation"
    }

    if (isGoogle) {
        return "googlebot"
    }

    return "none"
}

function getPaywallType(authType: AuthType, authorized: boolean) {
    if (authType === "googlebot") {
        return "none"
    }

    // Show paywall for users that are not logged in AND not authorized (do not show paywall for public articles)
    if (authType === "none" && !authorized) {
        return "not-logged-in"
    }

    // Show paywall for logged in users with missing subscription
    if (!authorized) {
        return "no-subscription"
    }

    return "none"
}
