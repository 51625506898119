import {
    PortableTextTypeComponentProps,
    UnknownNodeType
} from "@portabletext/react"
import { captureException } from "@sentry/nextjs"

export default function UnknownType(
    props: PortableTextTypeComponentProps<UnknownNodeType>
) {
    if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.error("### unknownType: ", props)
    } else {
        captureException(new Error("unknownType"))
    }
    return null
}
