import Typography from "@mui/material/Typography"
// eslint-disable-next-line no-restricted-imports
import { Variant } from "@mui/material/styles/createTypography"

export default function Heading({
    variant,
    children
}: {
    variant: Variant
    children: React.ReactNode
}) {
    return <Typography variant={variant}>{children}</Typography>
}
