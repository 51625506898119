
import dynamic from "next/dynamic"
const components = {
  alcoholaudit: dynamic(() => import("@nhi/calculators/alcoholaudit")),
  auditc: dynamic(() => import("@nhi/calculators/auditc")),
  barthel: dynamic(() => import("@nhi/calculators/barthel")),
  bodymassindex: dynamic(() => import("@nhi/calculators/bodymassindex")),
  braden: dynamic(() => import("@nhi/calculators/braden")),
  calorieneeds: dynamic(() => import("@nhi/calculators/calorieneeds")),
  cha2ds2vasc: dynamic(() => import("@nhi/calculators/cha2ds2vasc")),
  crb65: dynamic(() => import("@nhi/calculators/crb65")),
  dehydration: dynamic(() => import("@nhi/calculators/dehydration")),
  dementiaquestionnaire: dynamic(() => import("@nhi/calculators/dementiaquestionnaire")),
  docssf: dynamic(() => import("@nhi/calculators/docssf")),
  drugsdriverlicence: dynamic(() => import("@nhi/calculators/drugsdriverlicence")),
  egfr: dynamic(() => import("@nhi/calculators/egfr")),
  functionalreach: dynamic(() => import("@nhi/calculators/functionalreach")),
  gbsdementia: dynamic(() => import("@nhi/calculators/gbsdementia")),
  gcs: dynamic(() => import("@nhi/calculators/gcs")),
  gout: dynamic(() => import("@nhi/calculators/gout")),
  hasbled: dynamic(() => import("@nhi/calculators/hasbled")),
  hba1c: dynamic(() => import("@nhi/calculators/hba1c")),
  hearingloss: dynamic(() => import("@nhi/calculators/hearingloss")),
  ipss: dynamic(() => import("@nhi/calculators/ipss")),
  ldlcholesterol: dynamic(() => import("@nhi/calculators/ldlcholesterol")),
  lorenz: dynamic(() => import("@nhi/calculators/lorenz")),
  majordepression: dynamic(() => import("@nhi/calculators/majordepression")),
  mmsenr3: dynamic(() => import("@nhi/calculators/mmsenr3")),
  news2: dynamic(() => import("@nhi/calculators/news2")),
  niigata: dynamic(() => import("@nhi/calculators/niigata")),
  norrisk1: dynamic(() => import("@nhi/calculators/norrisk1")),
  norrisk2: dynamic(() => import("@nhi/calculators/norrisk2")),
  obsdemens: dynamic(() => import("@nhi/calculators/obsdemens")),
  pefnomogram: dynamic(() => import("@nhi/calculators/pefnomogram")),
  pevs: dynamic(() => import("@nhi/calculators/pevs")),
  rdrs2: dynamic(() => import("@nhi/calculators/rdrs2")),
  smoke: dynamic(() => import("@nhi/calculators/smoke")),
  startback: dynamic(() => import("@nhi/calculators/startback")),
  sukks: dynamic(() => import("@nhi/calculators/sukks")),
  term: dynamic(() => import("@nhi/calculators/term")),
  tweak: dynamic(() => import("@nhi/calculators/tweak")),
  vertigo: dynamic(() => import("@nhi/calculators/vertigo")),
  waistheightindex: dynamic(() => import("@nhi/calculators/waistheightindex")),
  waisthipindex: dynamic(() => import("@nhi/calculators/waisthipindex")),
  warfarindosage: dynamic(() => import("@nhi/calculators/warfarindosage")),
  weightloss: dynamic(() => import("@nhi/calculators/weightloss")),
  wellsdvt: dynamic(() => import("@nhi/calculators/wellsdvt")),
  wellsle: dynamic(() => import("@nhi/calculators/wellsle")),
}

export default components