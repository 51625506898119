import { PortableTextTypeComponentProps } from "@portabletext/react"
import Link from "components/Link"
import * as React from "react"

import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import { styled } from "@mui/material/styles"

import { signIn } from "lib/next-auth"

interface ContentButtonBaseProps
    extends Pick<MuiButtonProps, "fullWidth" | "title"> {
    method: string
    style: string
    text: JSX.Element
    url: string
    id: string | null
}

type ContentButtonProps = PortableTextTypeComponentProps<ContentButtonBaseProps>

export default function Button({ value }: ContentButtonProps) {
    const { url: href, style, text: children, id: id, ...rest } = value

    const color: MuiButtonProps["color"] =
        style === "primary" ? "primary" : "secondary"

    if (id?.endsWith("login-button")) {
        return <SignInButton id={id}>{children}</SignInButton>
    }

    return (
        <StyledLink
            button
            disableRipple
            variant="contained"
            size="large"
            color={color}
            href={href}
            id={id ?? ""}
            {...rest}>
            {children}
        </StyledLink>
    )
}
const StyledLink = styled(Link)(({ theme }) => ({
    margin: theme.spacing(0.5, 0),
    boxShadow: "none",
    "&:focus": {
        outline: "none",
        boxShadow: `0 0 0 3px ${theme.palette.secondary?.light}`
    }
}))

function SignInButton({ id, children }: { id: string; children: JSX.Element }) {
    const handleSignIn = () => {
        if (id.endsWith("force-login-button")) {
            signIn({
                authorization: {
                    forceLogin: "true",
                    prompt: "login"
                }
            })
        } else {
            signIn()
        }
    }

    return (
        <MuiButton
            onClick={handleSignIn}
            size="large"
            fullWidth
            sx={{ my: 0.5 }}
            color="secondary"
            variant="contained"
            id={id}>
            {children}
        </MuiButton>
    )
}
