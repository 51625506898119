import { t } from "locales"
import * as React from "react"

import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import { styled } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

export default function CollapsibleList({
    children,
    title,
    collapsed
}: {
    children: React.ReactNode
    title: JSX.Element
    collapsed?: boolean
}) {
    const [open, setOpen] = React.useState(!collapsed)
    const isPrinting = useMediaQuery("print")

    React.useEffect(() => {
        setOpen(isPrinting)
    }, [isPrinting])

    return (
        <ul>
            <li>
                <Toggler>
                    {title}
                    <StyledButton
                        color="secondary"
                        onClick={() => setOpen(prev => !prev)}
                        size="small">
                        {t["show-more"]}
                    </StyledButton>
                </Toggler>
                <Collapse in={open}>{children}</Collapse>
            </li>
        </ul>
    )
}

const Toggler = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    gap: theme.spacing(2),
    "& > p": {
        margin: 0
    }
}))

const StyledButton = styled(Button)(({ theme }) => ({
    whiteSpace: "nowrap",
    padding: theme.spacing(0.25, 0.5),
    "& @media print": {
        display: "none!important"
    }
}))
