import { PortableTextBlock } from "@portabletext/types"
import { captureMessage } from "@sentry/nextjs"

import { HttpError, get } from "@nhi/utils"

/**
 * Fetch avaliable regions for current handbook
 * @returns string[]
 */
export async function getRegions(): Promise<string[]> {
    return await get<string[]>(
        process.env.NEXT_PUBLIC_CONTENT_API + "/Config/Regions"
    )
}

export interface IFragment {
    title?: string
    blockContent: PortableTextBlock[]
}

/**
 * Load fragments from config-folder in CMS
 * @param path Path to the config document (without _config)
 * @returns Title and blockContent for fragment
 */
export async function getFragment(path: string): Promise<IFragment | null> {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        "/Config/Fragment?path=" +
        encodeURIComponent(path)

    try {
        const response = await get<IFragment>(endpoint)

        if (response instanceof Response && response.status === 204) {
            return null
        }

        return response
    } catch (error) {
        captureMessage(
            "Error fetching fragment from content-api: " + endpoint,
            "error"
        )
        return null
    }
}

export interface IDictionaryWord {
    words: string[]
    description: string
}

/**
 * Get complete dictionary
 * @returns List of dictionary words with description
 */
export async function getDictionary() {
    const endpoint = process.env.NEXT_PUBLIC_CONTENT_API + "/Config/Dictionary"
    return await get<IDictionaryWord[]>(endpoint)
}

/**
 * Look up a term, fetch synonyms and description
 * @param term Term to look up
 * @returns IDictionaryWord with synonyms and description
 */
export async function getDictionaryTerm(
    term: string | undefined
): Promise<IDictionaryWord | undefined> {
    if (!term) return undefined

    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API + `/Config/Dictionary/${term}`
    return await get<IDictionaryWord>(endpoint, undefined).catch(
        (httpError: HttpError) => {
            if (httpError.response.status == 404) return undefined
            else throw httpError
        }
    )
}
