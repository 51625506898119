/**
 * @file A Sticky component with React Hooks.
 * @author Kevin Norris
 * @see https://github.com/streamich/react-use/blob/master/docs/useIntersection.md
 */

import { RefObject, useEffect, useState } from "react"

const defaultOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: "0px 0px -80%",
  threshold: 0.95,
}

/**
 * React sensor hook that tracks the changes in the intersection of a target element
 * with an ancestor element or with a top-level document's viewport.
 * Uses the Intersection Observer API and returns a IntersectionObserverEntry.
 * @example
 * ```jsx
 *import React from 'react'
 *import useIntersection from '@nhi/hooks/useIntersection'
 *
 *const Demo = () => {
 *  const intersectionRef = React.useRef(null)
 *  const intersection = useIntersection(intersectionRef, {
 *    root: null,
 *    rootMargin: '0px',
 *    threshold: 1
 *  })
 *
 *  return (
 *   <div ref={intersectionRef}>
 *     {intersection?.intersectionRatio < 1 ? 'Obscured' : 'Fully in view'}
 *   </div>
 *  )
 *}
 * ```
 */
const useIntersection = (
  ref: RefObject<HTMLElement>,
  options: IntersectionObserverInit = defaultOptions
): IntersectionObserverEntry | null => {
  const [intersectionObserverEntry, setIntersectionObserverEntry] =
    useState<IntersectionObserverEntry | null>(null)

  useEffect(() => {
    if (ref.current && typeof IntersectionObserver === "function") {
      const handler = (entries: IntersectionObserverEntry[]) => {
        setIntersectionObserverEntry(entries[0])
      }

      const observer = new IntersectionObserver(handler, options)
      observer.observe(ref.current)

      return () => {
        setIntersectionObserverEntry(null)
        observer.disconnect()
      }
    }
    return () => void 0
    // NOTE: options is sufficiently exhaustied here, so eslint can be disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.threshold, options.root, options.rootMargin, ref])

  return intersectionObserverEntry
}

export default useIntersection
