import { styled } from "@mui/material/styles"

const Blockquote = styled("blockquote")(({ theme }) => ({
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    paddingLeft: theme.spacing(2),
    marginLeft: 0,
    fontStyle: "italic"
}))

export default Blockquote
