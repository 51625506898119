import { t } from "locales"

import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"

import {
    useGetUserSettings,
    useUpdateUserSettings
} from "data/contentData/hooks/user.hooks"

export const DictionarySwitch = () => {
    const { data: userSettings } = useGetUserSettings()
    const { mutate, isLoading } = useUpdateUserSettings()

    const showMark = userSettings?.markWordsInDictionary ?? false

    const handleChange = () => {
        mutate({
            ...userSettings,
            markWordsInDictionary: !showMark
        })
    }

    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={showMark}
                        onChange={handleChange}
                    />
                }
                disabled={isLoading}
                label={t["profile"]["dictionary"]["toggle-mark-words"]}
            />
            <Typography variant="caption">
                {t.profile.dictionary.description}
            </Typography>
        </>
    )
}

export default DictionarySwitch
