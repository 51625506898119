import { get, put } from "@nhi/utils"

export type FavoriteSorterType = "abc" | "time" | "template"

export interface IFavorite {
    url: string
    name: string
    timestamp?: Date
    template: string
}

export interface IUserSettings {
    favorites?: {
        list?: IFavorite[]
        sortBy?: FavoriteSorterType
    }
    region?: string
    markWordsInDictionary?: boolean
    guid?: string
}

/**
 * Internal endpoint for updating user settings in ContentData
 * @param userSettings settings to be updated
 * @param token token to be used for authentication
 * @returns updated settings for the user
 */
export async function updateUserSettings(
    userSettings: IUserSettings,
    token: string | null | undefined
): Promise<IUserSettings> {
    return await put("/api/user-settings", JSON.stringify(userSettings), token)
}

interface IGetUserSettingsResponseModel {
    settings: string
}

/**
 * Internal endpoint for fetching user settings from ContentData
 * @param token token to be used for authentication
 * @returns settings for the user
 */
export async function getUserSettings(
    token: string | null | undefined
): Promise<IUserSettings | undefined> {
    const response = await get<IGetUserSettingsResponseModel>(
        "/api/user-settings",
        token
    )

    return response.settings
        ? (JSON.parse(response.settings) as IUserSettings)
        : {}
}
