import useUser from "hooks/useUser"
import { useMutation, useQuery, useQueryClient } from "react-query"

import {
    IUserSettings,
    getUserSettings,
    updateUserSettings
} from "data/contentData/api/user"
import isEnabled from "lib/is-enabled"

import { OptionsType } from "../../../@types/shared.types"

export enum UserCacheKeys {
    USER_SETTINGS = "user-settings"
}

export function useGetUserSettings(
    options?: OptionsType<IUserSettings | undefined>
) {
    const { authenticated, accessToken, isFetching } = useUser()

    return useQuery<IUserSettings | undefined, Error>(
        [UserCacheKeys.USER_SETTINGS],
        () => getUserSettings(accessToken),
        {
            ...options,
            enabled: isEnabled(authenticated && !isFetching, options)
        }
    )
}

export function useUpdateUserSettings() {
    const queryClient = useQueryClient()
    const { accessToken } = useUser()

    return useMutation(
        (settings: IUserSettings) => updateUserSettings(settings, accessToken),
        {
            onMutate: async settings => {
                // Cancel outgoing queries
                await queryClient.cancelQueries([UserCacheKeys.USER_SETTINGS])

                // Snapshot previous data
                const previousData = queryClient.getQueryData<IUserSettings>([
                    UserCacheKeys.USER_SETTINGS
                ])

                // Optimistic update
                if (previousData) {
                    queryClient.setQueryData([UserCacheKeys.USER_SETTINGS], {
                        ...settings
                    })
                }
                return { previousData: settings }
            },
            onError: (_, __, context: any) => {
                // revert if error
                if (context?.previousData) {
                    queryClient.setQueryData(
                        [UserCacheKeys.USER_SETTINGS],
                        context.previousData
                    )
                }
            },
            onSuccess: () => {
                queryClient.invalidateQueries(UserCacheKeys.USER_SETTINGS)
            }
        }
    )
}
