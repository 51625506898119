import Drawer from "components/Drawer/Drawer"
import { t } from "locales"
import { useSnackbar } from "notistack"
import * as React from "react"

import NotificationsIcon from "@mui/icons-material/NotificationsOutlined"
import Badge from "@mui/material/Badge"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Skeleton from "@mui/material/Skeleton"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import {
    useGetNotifications,
    useMarkNotificationAsRead
} from "data/contentData/hooks/notification.hooks"

import Notification from "./Notification"

export default function Notifications() {
    const [anchorEl, setAnchorEl] = React.useState<null | any>(null)
    const [open, setOpen] = React.useState(false)

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const { enqueueSnackbar } = useSnackbar()
    const { mutate: markAsRead } = useMarkNotificationAsRead()
    const { status, data: notifications } = useGetNotifications()

    const amount = notifications ? notifications.length : 0

    const handleMarkAsRead = (id: number) => () => {
        markAsRead({ id })
        enqueueSnackbar(t["notifications"]["notification-was-removed"], {
            variant: "info"
        })
    }

    return (
        <>
            <Tooltip title={t.header.notifications}>
                <IconButton
                    color="inherit"
                    ref={anchorEl}
                    aria-label={t.header.notifications}
                    onClick={handleOpen}
                    sx={{ p: 0.5 }}
                    size="large">
                    <Badge
                        badgeContent={amount}
                        color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Drawer
                header={
                    <Typography
                        align="center"
                        variant="subtitle2">
                        {t.notifications.title}
                    </Typography>
                }
                position="right"
                open={open}
                onClose={handleClose}>
                {status === "loading" ? (
                    <List>
                        {[...Array(3).keys()].map(id => (
                            <ListItem
                                divider
                                key={id}>
                                <ListItemText
                                    primary={
                                        <Skeleton
                                            height={24}
                                            width={150}
                                        />
                                    }
                                    secondary={
                                        <Skeleton
                                            height={20}
                                            width={65}
                                        />
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : null}

                {status === "success" ? (
                    !notifications || !notifications.length ? (
                        <Grid
                            container
                            justifyContent="center"
                            style={{ height: "100%" }}>
                            <Typography
                                variant="caption"
                                style={{ marginTop: "3em" }}>
                                {t["notifications"]["no-new-notifications"]}
                            </Typography>
                        </Grid>
                    ) : (
                        <List>
                            {notifications?.map((notification: any) => (
                                <Notification
                                    key={notification.id}
                                    {...notification}
                                    onMarkAsRead={handleMarkAsRead(
                                        notification.id
                                    )}
                                />
                            ))}
                        </List>
                    )
                ) : null}
            </Drawer>
        </>
    )
}
