import { PortableTextBlock } from "@portabletext/types"
import { NoteBaseProps } from "components/PortableText/Types/Notes/Note"
import { useQuery } from "react-query"

import { getHDIRGuideline } from "data/contentData/api/hdir"

import { OptionsType } from "../../../@types/shared.types"

export enum RealtimeGuidelineCacheKeys {
    REALTIME_GUIDELINE = "realtime-guideline"
}

export type RealtimeGuidelinesType = {
    id: string
    source: string
    blockContent: PortableTextBlock[]
} & NoteBaseProps

export function useGetHDIRGuidelines(
    id: string,
    path: string,
    options?: OptionsType<RealtimeGuidelinesType>
) {
    return useQuery<RealtimeGuidelinesType, Error>(
        [RealtimeGuidelineCacheKeys.REALTIME_GUIDELINE, id],
        async () => {
            const hdir = await getHDIRGuideline(id, path)

            return {
                ...hdir
            } as RealtimeGuidelinesType
        },
        {
            ...options
        }
    )
}
