import SkipNavLink from "components/SkipNav"
import * as React from "react"

import Footer from "./Footer"
import Header, { HeaderSimple } from "./Header"
import LoadingIndicator from "./LoadingIndicator"

interface LayoutProps {
    children: React.ReactNode
    isSimple?: boolean
}

export default function Layout({ children, isSimple }: LayoutProps) {
    return (
        <div id="layout-root">
            <SkipNavLink />
            <LoadingIndicator />
            {isSimple ? <HeaderSimple /> : <Header />}
            {children}
            {isSimple ? null : <Footer />}
        </div>
    )
}
