import useUser from "hooks/useUser"
import { useMutation } from "react-query"

import { ITicket, createTicket } from "data/contentData/api/ticket"

export function useCreateTicket() {
    const { accessToken } = useUser()

    return useMutation(async (ticket: ITicket) => {
        await createTicket(ticket, accessToken)
    })
}
