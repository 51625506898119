import { currentConfig } from "auth"
import Drawer from "components/Drawer/Drawer"
import Link from "components/Link"
import { general, rewrites } from "config/config"
import useUser from "hooks/useUser"
import { t } from "locales"
import RegionSelect from "modules/profile/RegionSelect"
import { User } from "next-auth"
import * as React from "react"

import ProfileIcon from "@mui/icons-material/Person"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { isLocalStorageSupported } from "@nhi/utils"
import { useGetOrganisation } from "data/customerManager/hooks/organisation.hooks"
import { signIn, signOut } from "lib/next-auth"

import DictionarySwitch from "./DictionarySwitch"

export default function Profile() {
    const { user } = useUser()

    const [anchorEl, setAnchorEl] = React.useState<null | any>(null)
    const [open, setOpen] = React.useState(false)

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const organisation = useGetOrganisation()

    const initials = (user?.name as string)
        ?.split(" ")
        .map(n => n[0])
        .slice(0, 2)
        .join("")

    const profileIcon = initials ?? <ProfileIcon />

    return (
        <>
            <Tooltip title={t.header.profile}>
                <IconButton
                    ref={anchorEl}
                    onClick={handleOpen}
                    size="large"
                    sx={{ fontSize: "20px" }}
                    aria-label={t.header.profile}>
                    <StyledAvatar>{profileIcon}</StyledAvatar>
                </IconButton>
            </Tooltip>
            <Drawer
                position="right"
                open={open}
                onClose={handleClose}>
                {organisation.authenticated && !user ? (
                    <ProfileOrganisation name={organisation.name ?? ""} />
                ) : (
                    <Grid
                        container
                        direction="column"
                        padding={2}>
                        <Grid item>
                            <ProfileHeader
                                profileIcon={profileIcon}
                                user={user}
                            />
                        </Grid>
                        {user ? (
                            <Grid item>
                                <UserSettings />
                            </Grid>
                        ) : null}
                        <Grid item>
                            <ProfileFooter user={user} />
                        </Grid>
                    </Grid>
                )}
            </Drawer>
        </>
    )
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    background: "transparent",
    border: `2px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white,
    height: 42,
    width: 42,
    fontSize: "1rem"
}))

function ProfileOrganisation({ name }: { name: string }) {
    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
            sx={{ py: 4 }}>
            <Typography
                variant="body2"
                textAlign="center">
                {t["profile"]["logged-in-as-organisation"]}
            </Typography>
            <Typography
                variant="body1"
                textAlign="center">
                {name}
            </Typography>
        </Grid>
    )
}

function ProfileHeader({
    profileIcon,
    user
}: {
    profileIcon: string | JSX.Element
    user: User | undefined
}) {
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            sx={{ mt: 2 }}>
            <HeaderAvatar>{profileIcon}</HeaderAvatar>
            {user ? (
                <>
                    <Typography
                        align="center"
                        component="p"
                        variant="subtitle1">
                        {user.name}
                    </Typography>
                    <Typography
                        align="center"
                        color="textSecondary"
                        component="p"
                        variant="body2"
                        sx={{ mb: 2 }}>
                        {user.email}
                    </Typography>
                    <Link
                        button
                        shallow
                        color="secondary"
                        href={currentConfig.editProfileUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        variant="outlined">
                        {t.profile.edit}
                    </Link>
                </>
            ) : (
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{ py: 2, gap: 2 }}>
                    <Typography variant="h2">
                        {t["profile"]["logged-out"]["login-title"]}
                    </Typography>
                    <Typography
                        variant="body2"
                        align="center">
                        {t["profile"]["logged-out"]["login-text"][0]}
                    </Typography>
                    <Typography
                        variant="body2"
                        align="center"
                        style={{ marginTop: 0, marginBottom: 16 }}>
                        {t["profile"]["logged-out"]["login-text"][1]}
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                            const { pathname } = window.location
                            if (
                                pathname.includes(rewrites["/logged-out"]) ||
                                pathname.includes(
                                    rewrites["/concurrent-logins"]
                                )
                            ) {
                                return signIn({ options: { callbackUrl: "/" } })
                            }
                            signIn()
                        }}>
                        {t["profile"]["logged-out"]["login-button"]}
                    </Button>
                    <Typography variant="body2">
                        {t["profile"]["logged-out"]["subscribe-text"]}
                    </Typography>
                    <Link
                        fullWidth
                        button
                        variant="outlined"
                        color="secondary"
                        href={general.orderPagesURL}>
                        {t["profile"]["logged-out"]["subscribe-button"]}
                    </Link>
                </Grid>
            )}
        </Grid>
    )
}

const HeaderAvatar = styled(Avatar)(() => ({
    backgroundColor: "transparent",
    border: "2px solid black",
    color: "black",
    fontSize: "1.25rem",
    height: 64,
    width: 64
}))

function UserSettings() {
    if (!general.enableRegionalContent && !general.enableDictionary) {
        return null
    }

    return (
        <>
            <Divider
                variant="fullWidth"
                sx={{ mx: -2, my: 2 }}
            />
            <Grid
                container
                spacing={2}
                direction="column">
                {general.enableRegionalContent ? (
                    <Grid
                        container
                        item>
                        <RegionSelect />
                    </Grid>
                ) : null}

                {general.enableDictionary ? (
                    <Grid
                        container
                        item
                        direction="column">
                        <DictionarySwitch />
                    </Grid>
                ) : null}
            </Grid>
        </>
    )
}

function ProfileFooter({ user }: { user: User | undefined }) {
    return (
        <>
            <Divider
                variant="fullWidth"
                sx={{ mx: -2, my: 2 }}
            />
            <Grid
                container
                direction="column"
                alignItems="center">
                {user ? (
                    <Button
                        onClick={() => {
                            if (isLocalStorageSupported()) {
                                localStorage.removeItem("intro-visited")
                                localStorage.removeItem(
                                    "external-link-popup-disabled"
                                )
                            }
                            if (window.location.href.includes("localhost")) {
                                signOut({
                                    callbackUrl: rewrites["/logged-out"]
                                })
                            } else {
                                sessionStorage.setItem("force-logout", "1")
                                window.location.href =
                                    "/api/auth/federated-logout"
                            }
                        }}
                        size="large"
                        variant="outlined">
                        {t.logout}
                    </Button>
                ) : (
                    <Link
                        color="secondary"
                        href="/kontakt">
                        {t.profile.contact}
                    </Link>
                )}
            </Grid>
        </>
    )
}
