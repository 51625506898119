import { PortableTextTypeComponentProps } from "@portabletext/react"
import * as React from "react"

export interface SourceProps {
    src: string
}

export default function Source({
    value
}: PortableTextTypeComponentProps<SourceProps>) {
    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio
            controls
            src={value.src}
        />
    )
}
