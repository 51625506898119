/* eslint-disable @typescript-eslint/no-shadow */
import { PortableTextTypeComponentProps } from "@portabletext/react"
import { general } from "config/config"
import * as React from "react"
import { useEffect } from "react"

import Skeleton from "@mui/material/Skeleton"
import { styled } from "@mui/material/styles"

import components from "@nhi/calculators/dynamicComponents"
import { CalculatorBaseProps } from "@nhi/calculators/shared"

type Components = typeof components
type CalculatorId = keyof Components

type CalculatorProps = CalculatorBaseProps & {
    id: CalculatorId
}

type CalculatorIframeProps = PortableTextTypeComponentProps<CalculatorProps>

export function Calculator(props: CalculatorProps) {
    const [Calculator, setCalculator] =
        React.useState<Components[CalculatorId]>()

    React.useEffect(() => {
        const getCalculator = async () => {
            const Calculator = components[props.id]
            if (Calculator) {
                setCalculator(() => Calculator)
                return
            }
        }
        getCalculator()
    }, [props.id])

    if (Calculator) {
        return <Calculator {...props} />
    }
    return <Skeleton />
}

//uncomment when new calcs are approved for use , and remove when all calculators are migrated

// const newCalculators = [
//     { id: "Barthel", oldId: "barthel" },
//     { id: "BodyMassIndex", oldId: "bodymassindex" },
//     { id: "DrugCalculatorDriversLicense", oldId: "drugsdriverlicence" },
//     { id: "CHA2DS2-VASc", oldId: "cha2ds2vasc" },
//     { id: "WaistHipIndex", oldId: "waisthipindex" },
//     { id: "IPSS", oldId: "ipss" },
//     { id: "NORRISK2", oldId: "norrisk2" },
//     { id: "Pefnomogram", oldId: "pefnomogram" },
//     { id: "Sukk-s", oldId: "sukks" }
// ]

//if the calculator is in the new calculators list, return the new calculator id

// const useNewCalculator = (id: string) => {
//     let calculatorId: undefined | string
//     newCalculators.map(calc => {
//         if (calc.oldId === id) calculatorId = calc.id
//     })
//     return calculatorId
// }

export default function CalculatorIframe({ value }: CalculatorIframeProps) {
    const id = value.id
    if (!id) {
        return null
    }

    //when the new calculators are approved for use, remove this
    const calculatorId = undefined
    //and uncomment this
    //const calculatorId = useNewCalculator(id)

    //remove this if-condition when all calculators are migrated
    if (calculatorId !== undefined) {
        return (
            <AutoSizeIframe
                src={`${process.env.NEXT_PUBLIC_CALCULATORS_URL}/${calculatorId}`}
                title={calculatorId}
            />
        )
    }

    return (
        <AutoSizeIframe
            src={`/calculators/${id}`}
            title={id}
        />
    )
}

const AutoSizeIframe = ({ src, title }: { src: string; title: string }) => {
    useEffect(() => {
        const handleResizeEvent = (event: MessageEvent<HTMLIFrameElement>) => {
            if (
                event.origin === general.appHost ||
                event.origin === process.env.NEXT_PUBLIC_CALCULATORS_URL
            ) {
                const { height } = event.data
                if (height) {
                    const iframe = document.getElementById("my-iframe")

                    if (iframe) {
                        iframe.style.height = `${height}px`
                    }
                }
            }
        }
        window.addEventListener("message", handleResizeEvent)
        return () => {
            window.removeEventListener("message", handleResizeEvent)
        }
    }, [])

    return (
        <StyledIframe
            id="my-iframe"
            src={src}
            title={title}
        />
    )
}

const StyledIframe = styled("iframe")(() => ({
    width: "100%",
    border: "none"
}))
