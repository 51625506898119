import { t } from "locales"
import * as React from "react"

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { toLocalDate } from "@nhi/utils"

interface INotificationProps {
    id: number
    subject: string
    body: string
    createdUtc: Date
    onMarkAsRead: () => void
}

export default function Notification({
    subject,
    body,
    createdUtc,
    onMarkAsRead
}: INotificationProps) {
    const [open, setOpen] = React.useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <ListItem
                divider
                button
                onClick={handleClick}>
                <ListItemText
                    primary={subject}
                    secondary={toLocalDate(
                        createdUtc,
                        process.env.NEXT_PUBLIC_LOCALE
                    )}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit>
                <Message>
                    <Typography
                        component="div"
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: body }}
                    />
                    <Actions>
                        <Button
                            startIcon={<CheckOutlinedIcon />}
                            variant="text"
                            color="secondary"
                            onClick={onMarkAsRead}>
                            {t.notifications["remove-notification"]}
                        </Button>
                    </Actions>
                </Message>
            </Collapse>
        </>
    )
}

const Message = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2)
}))

const Actions = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end"
}))
