import { PortableTextMarkComponent } from "@portabletext/react"
import Link from "components/Link"
import PortableText from "components/PortableText/PortableText"
import { rewrites } from "config/config"
import * as React from "react"

import Grid, { GridSize } from "@mui/material/Grid"
import MuiLink from "@mui/material/Link"
import { styled } from "@mui/material/styles"

import { IFooterCard } from "data/contentData/api/content"

export default function FooterCard({
    title,
    image,
    columns,
    blockContent
}: IFooterCard) {
    return (
        <Grid
            item
            sx={{
                textAlign: columns === 12 ? "center" : "inherit",
                "& img": {
                    objectFit: "cover"
                }
            }}
            md={columns as GridSize}
            xs={12}>
            {image ? (
                <StyledImg
                    alt={title}
                    src={image.src}
                />
            ) : null}

            {blockContent && (
                <PortableText
                    value={blockContent}
                    customComponents={{
                        marks: {
                            link: FooterLink
                        }
                    }}
                />
            )}
        </Grid>
    )
}

const StyledImg = styled("img")(() => ({
    maxWidth: "100%",
    objectFit: "contain",
    overflow: "hidden"
}))

const FooterLink: PortableTextMarkComponent = ({ children, value }) => {
    const { href, target } = value

    const styles = {
        color: "link.dark",
        textDecoration: "underline"
    }

    if (href.startsWith(rewrites["/cookie-declaration"])) {
        return (
            <MuiLink
                sx={styles}
                href={href}
                target={target}>
                {children}
            </MuiLink>
        )
    }

    return (
        <Link
            sx={styles}
            href={href}
            target={target}>
            {children}
        </Link>
    )
}
