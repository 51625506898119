import { get } from "@nhi/utils"

export interface IHDIRGuideline {
    id: string
    blockContent: string
    source: string
}

/**
 * Get HDIR guideline
 * @param id Id of the guideline
 * @returns IHDIRGuideline
 */
export async function getHDIRGuideline(id: string, path: string) {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        `/Hdir/Guideline?id=${id}&path=${path}`
    return await get<IHDIRGuideline>(endpoint)
}
