import SkipNav from "components/SkipNav"
import { rewrites } from "config/config"
import { t } from "locales"
import { NextRouter, useRouter } from "next/router"
import * as React from "react"

import SearchIcon from "@mui/icons-material/Search"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import useDebounce from "@nhi/hooks/useDebounce"
import { isQueryValid, normalizeQuery } from "@nhi/utils"
import {
    ISearchSuggestionsAndAutocomlete,
    useGetSearchSuggestions
} from "data/contentData/hooks/search.hooks"

import SearchCombobox from "./SearchCombobox"

export interface SuggestionOrTermOption {
    group: "history" | "term" | string
    name: string
    topic?: string
    type?: string
    id?: string
    url: string
}

function useQuery(router: NextRouter) {
    const [q, setQ] = React.useState<string>(
        () => (router.query.q as string) ?? ""
    )

    React.useEffect(() => {
        if (router.query.q) {
            setQ(router.query.q as string)
        }
    }, [router.query.q])
    const debouncedQuery = useDebounce(q, 200)

    return { q, setQ, debouncedQuery }
}

function useFormatSuggestions(
    data: ISearchSuggestionsAndAutocomlete | undefined
) {
    // state, suggestion
    const [suggestions, setSuggestions] = React.useState<
        SuggestionOrTermOption[]
    >([])

    // every time data changes, format data
    React.useEffect(() => {
        if (!data) {
            return
        }

        const { autocomplete, suggestionGroups } = data

        const newOptions: any[] = []

        if (autocomplete.length > 0) {
            newOptions.push(
                {
                    type: "subheader",
                    name: t["search"]["improve-search"],
                    group: "term",
                    id: `subheader-${t["search"]["improve-search"]}`
                },
                ...autocomplete.map((term, index) => ({
                    group: "term",
                    name: term,
                    type: "suggestion",
                    id: `button-term-${term}-${index}`
                }))
            )
        }

        suggestionGroups.forEach((suggestionGroup, groupIndex) => {
            newOptions.push(
                {
                    type: "subheader",
                    name: suggestionGroup.name,
                    group: suggestionGroup.name,
                    id: `subheader-${suggestionGroup.name}-${groupIndex}`
                },
                ...suggestionGroup.suggestions.map(
                    (suggestion, suggestionIndex) => ({
                        group: suggestionGroup.name,
                        type: "suggestion",
                        id: `button-${suggestionGroup.name}-${suggestion.name}-${suggestionIndex}`,
                        ...suggestion
                    })
                )
            )
        })

        setSuggestions(newOptions)
    }, [data, setSuggestions])

    const resetOptions = () => {
        setSuggestions([])
    }

    return {
        suggestions,
        resetOptions
    }
}

/** The number of last searches saved on the user */
export default function SearchField() {
    const router = useRouter()
    const mdUp = useMediaQuery(useTheme().breakpoints.up("md"))
    const [focusedSearch, setFocusedSearch] = React.useState(false)
    const { q, setQ, debouncedQuery } = useQuery(router)

    const { status, data } = useGetSearchSuggestions(
        normalizeQuery(debouncedQuery, "suggestion"),
        {
            enabled: isQueryValid(debouncedQuery, "suggestion")
        }
    )

    const { suggestions, resetOptions } = useFormatSuggestions(data)

    const pathname = router.pathname.startsWith("/search-dev")
        ? "/search-dev"
        : rewrites["/search"]

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (!isQueryValid(q, "search")) {
            return
        }

        await router.push({
            pathname,
            query: { q }
        })
    }

    const handleOnSelect = async (
        value: string | SuggestionOrTermOption | null
    ) => {
        if (value === null) {
            return resetOptions()
        }
        if (typeof value === "string" || value.group === "term") {
            const _q = typeof value === "string" ? value : value.name

            if (!isQueryValid(_q, "suggestion")) {
                return
            }

            await router.push({
                pathname,
                query: { q: _q }
            })
        } else {
            await router.push(value.url)
        }
    }

    const handleInputChange = (value: string) => {
        setQ(value)
    }

    return (
        <StyledGrid
            onFocus={() => setFocusedSearch(true)}
            onBlur={() => setFocusedSearch(false)}
            focused={focusedSearch.toString()}>
            <Grid
                container
                alignItems="center"
                component="form"
                onSubmit={handleSubmit}>
                <Grid
                    item
                    xs>
                    <SearchCombobox
                        suggestions={suggestions}
                        onChange={handleInputChange}
                        onSelect={handleOnSelect}
                        status={status}
                        initialValue={q}
                    />
                </Grid>
                <IconButton
                    aria-label={t.search.search}
                    color="secondary"
                    type="submit"
                    size="small"
                    sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton>
                {mdUp && <SkipNav />}
            </Grid>
        </StyledGrid>
    )
}

const StyledGrid = styled(Grid)<{ focused: string }>(({ theme, focused }) => ({
    position: "relative",
    margin: theme.spacing(1),
    padding: 0,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    outline:
        focused === "true"
            ? `${theme.spacing(0.35)} solid ${theme.palette.secondary.light}`
            : "none"
}))
