import { AccessTokenResponseType, AuthConfigType } from "auth"
import { auth, general } from "config/config"
import IDS4, { IDS4Profile } from "next-auth/providers/identity-server4"

/**
 * Access token url for server side requests
 */
const accessTokenUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/connect/token`

/**
 * Edit profile url - used to navigate user to edit profile page
 */
const editProfileUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/profile?returnUrl=${general.appHost}&clientId=${auth.clientId}`

/**
 * End session url - used to log user out from identity server
 */
const endSessionUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/connect/endsession`

/**
 * IdentityServer4 provider setup for next-auth
 */
const provider = IDS4({
    clientId: auth.clientId,
    clientSecret: process.env.AUTH_CLIENT_SECRET,
    issuer: process.env.NEXT_PUBLIC_AUTH_ISSUER,
    httpOptions: {
        timeout: 5000
    },
    idToken: true,
    authorization: {
        params: {
            scope: "openid offline_access contentdata-api handbook customer-manager-api",
            allowIpLogin: true,
            ipRequiredRole: "Handbook.Subscriber"
        }
    },
    profile(p) {
        const profile = p as IDS4Profile
        const name =
            profile["http://nhi.no/Events/Customer/Fullname"] ??
            profile[
                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
            ]

        const roleOrRoles =
            profile[
                "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ] ?? []
        const roles = (
            Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles]
        ).filter(role => role.startsWith("Handbook."))

        const concurrency = parseInt(
            profile["http://nhi.no/Events/Customer/HandbookConcurrency"] ||
                auth.defaultMaxConcurrentLogins.toString(),
            10
        )

        return {
            id: profile.sub ?? "",
            guid: profile["http://nhi.no/Events/Customer/CustomerGuid"] ?? "",
            parentId: profile["http://nhi.no/Events/Customer/ParentGuid"],
            email: profile.email,
            organisationAffiliation:
                profile[
                    "http://nhi.no/Events/Customer/OrganisationAffiliation"
                ],
            professionName:
                profile["http://nhi.no/Events/Customer/ProfessionName"] ?? "",
            name,
            concurrency,
            roles
        }
    },
    checks: ["pkce"]
})

/**
 * Get JWT to authenticate requests from frontend-backend to supported services
 * @returns access token and expiration time
 */
async function getServerSideAccessToken(): Promise<AccessTokenResponseType> {
    const url = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/connect/token`

    const response = await fetch(url, {
        body: new URLSearchParams({
            client_id: "next-handbook-server",
            client_secret: process.env.IDS_SERVER_SIDE_SECRET,
            grant_type: "client_credentials",
            scope: ""
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "post"
    })

    const accessToken: AccessTokenResponseType = await response.json()

    if (response.ok) {
        return {
            ...accessToken,
            // Give a 10 sec buffer
            expires_in: accessToken.expires_in - 10
        }
    }

    throw {
        message: "AccessTokenError",
        accessToken
    }
}

export const identityServerConfig: AuthConfigType = {
    provider,
    accessTokenUrl,
    editProfileUrl,
    endSessionUrl,
    getServerSideAccessToken
}
