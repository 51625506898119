import * as React from "react"

import { FavoriteSorterType, IFavorite } from "data/contentData/api/user"
import {
    useGetUserSettings,
    useUpdateUserSettings
} from "data/contentData/hooks/user.hooks"

type DrawerSorterFn = (a: IFavorite, b: IFavorite) => number

export const sorters: Record<FavoriteSorterType, DrawerSorterFn> = {
    abc: (a, b) => a.name.localeCompare(b.name),
    time: (a, b) =>
        new Date(b.timestamp as Date).valueOf() -
        new Date(a.timestamp as Date).valueOf(),
    template: (a, b) => a.template.localeCompare(b.template)
}

/**
 * Hook to add current page to favorites,
 * or remove if already added
 */
const useFavorites = (
    id?: string
): {
    favorite?: IFavorite
    favorites: IFavorite[]
    toggle: (favorite?: IFavorite) => void
    add: (favorite: IFavorite) => void
    remove: (id: string) => void
    sort: (sortBy: FavoriteSorterType) => void
    sortBy: FavoriteSorterType
    loading: boolean
} => {
    const { data: userSettings } = useGetUserSettings()
    const { mutate, isLoading } = useUpdateUserSettings()

    const sortBySetting = userSettings?.favorites?.sortBy ?? "abc"
    const list = React.useMemo(
        () => userSettings?.favorites?.list ?? [],
        [userSettings?.favorites?.list]
    )
    const favorites = list.sort(sorters[sortBySetting])

    const remove = React.useCallback(
        (url: string) => {
            const newUserSettings = {
                ...userSettings,
                favorites: {
                    sortBy: sortBySetting,
                    list: list.filter(p => p.url !== url)
                }
            }

            mutate(newUserSettings)
        },
        [list, mutate, sortBySetting, userSettings]
    )

    const add = React.useCallback(
        (favorite: IFavorite) => {
            if (!favorite.timestamp) {
                favorite.timestamp = new Date()
            }

            const newUserSettings = {
                ...userSettings,
                favorites: {
                    sortBy: sortBySetting,
                    list: [...list, favorite]
                }
            }
            mutate(newUserSettings)
        },
        [list, mutate, sortBySetting, userSettings]
    )

    const getFavorite = React.useCallback(
        (passedId: string) => {
            return list.find(f => f.url === passedId)
        },
        [list]
    )

    const toggle = React.useCallback(
        (favorite?: IFavorite) => {
            if (!favorite?.name) {
                return
            }
            const added = getFavorite(favorite.url)
            if (added) {
                remove(favorite.url)
            } else {
                add(favorite)
            }
        },
        [add, getFavorite, remove]
    )

    const sort = React.useCallback(
        (sortBy: FavoriteSorterType) => {
            const newUserSettings = {
                ...userSettings,
                favorites: {
                    list,
                    sortBy: sortBy
                }
            }
            mutate(newUserSettings)
        },
        [list, mutate, userSettings]
    )

    return {
        add,
        favorite: id ? getFavorite(id) : undefined,
        favorites,
        loading: isLoading,
        remove,
        sort,
        sortBy: sortBySetting,
        toggle
    }
}

export default useFavorites
