import { get } from "@nhi/utils"

export interface IOrder {
    data: {
        id: string
        user_id: string
        order_number: string
        status: "PENDING" | "PAID" | "CANCELED"
    }[]
    total: number
}

export async function getActiveCDSOrders(
    token: string | null | undefined
): Promise<IOrder | null> {
    const url = `${process.env.NEXT_PUBLIC_PROLEX_SHOP_API}/user/orders?is_expired=false&status=PAID&client_id=cds-ui&type=SUBSCRIPTION`
    return await get<IOrder | null>(url, token)
}
