import {
    PortableTextProps,
    PortableTextReactComponents,
    PortableText as SanityPortableText
} from "@portabletext/react"
import { captureException } from "@sentry/nextjs"
import * as React from "react"

import Blockquote from "./Block/BlockQuote"
import Heading from "./Block/Heading"
import Anchor from "./Marks/Anchor"
import Link from "./Marks/Link"
import Quote from "./Marks/Quote"
import Sub from "./Marks/Sub"
import Sup from "./Marks/Sup"
import Audio from "./Types/Audio"
import Button from "./Types/Button"
import CalculatorIframe from "./Types/Calculator"
import EasyLMS from "./Types/EasyLMS"
import Image from "./Types/Image"
import ImageList from "./Types/ImageList"
import ImageWithGalleryLink from "./Types/ImageWithGalleryLink"
import Note from "./Types/Notes/Note"
import RealtimeGuideline from "./Types/Notes/RealtimeGuideline"
import Placeholder from "./Types/Placeholder"
import ReferenceInline from "./Types/ReferenceInline"
import Source from "./Types/Source"
import Table from "./Types/Table"
import VideoEmbed from "./Types/VideoEmbed"
import UnknownMark from "./UnknownMark"
import UnknownType from "./UnknownType"

interface IPortableTextProps extends PortableTextProps {
    customComponents?: Partial<PortableTextReactComponents>
    wrapImgWithGalleryLink?: boolean
}

export default function PortableText(props: IPortableTextProps) {
    const { customComponents, wrapImgWithGalleryLink, ...rest } = props

    if (!rest.value) {
        return null
    }

    return (
        <SanityPortableText
            {...rest}
            components={{
                marks: {
                    ...defaultComponents.marks,
                    ...customComponents?.marks
                },
                types: {
                    ...defaultComponents.types,
                    ...customComponents?.types,
                    image: wrapImgWithGalleryLink ? ImageWithGalleryLink : Image
                },
                block: customComponents?.block ?? defaultComponents.block,
                hardBreak:
                    customComponents?.hardBreak ?? defaultComponents.hardBreak,
                list: customComponents?.list ?? defaultComponents.list,
                listItem:
                    customComponents?.listItem ?? defaultComponents.listItem
            }}
            onMissingComponent={(message, options) => {
                if (process.env.NODE_ENV === "development") {
                    // eslint-disable-next-line no-console
                    console.error("###", message, options)
                } else {
                    captureException(new Error(message))
                }
                return null
            }}
        />
    )
}

const defaultComponents: Partial<PortableTextReactComponents> = {
    marks: {
        anchor: Anchor,
        link: Link,
        subscript: Sub,
        superscript: Sup,
        quote: Quote
    },
    types: {
        button: Button,
        "image-list": ImageList,
        image: Image,
        note: Note,
        "realtime-guideline": RealtimeGuideline,
        ref: ReferenceInline,
        table: Table,
        "video-embed": VideoEmbed,
        audio: Audio,
        source: Source,
        placeholder: Placeholder,
        calculator: CalculatorIframe,
        "easy-lms": EasyLMS
    },
    block: {
        h1: ({ children }) => <Heading variant="h1">{children}</Heading>,
        h2: ({ children }) => <Heading variant="h2">{children}</Heading>,
        h3: ({ children }) => <Heading variant="h3">{children}</Heading>,
        h4: ({ children }) => <Heading variant="h4">{children}</Heading>,
        h5: ({ children }) => <Heading variant="h5">{children}</Heading>,
        h6: ({ children }) => <Heading variant="h6">{children}</Heading>,
        blockquote: ({ children }) => <Blockquote>{children}</Blockquote>
    },
    hardBreak: ({ children }) => (
        <>
            {children}
            <br />
        </>
    ),
    list: ({ children }) => <ul>{children}</ul>,
    listItem: ({ children }) => <li>{children}</li>,
    unknownMark: UnknownMark,
    unknownType: UnknownType
}
