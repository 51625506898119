import { t } from "locales"
import * as React from "react"

import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { SelectChangeEvent } from "@mui/material/Select"

import { useGetRegions } from "data/contentData/hooks/config.hooks"
import {
    useGetUserSettings,
    useUpdateUserSettings
} from "data/contentData/hooks/user.hooks"

export default function RegionSelect(props: {
    /**
     * RegionSelect shows all the regions available.
     * If `enabled` is defined, all regions not present in this list
     * will be shown as disabled.
     **/
    enabled?: string[]
}) {
    const { enabled } = props
    const { data: regions = [], status } = useGetRegions()
    const { data: userSettings } = useGetUserSettings()
    const { mutate, isLoading } = useUpdateUserSettings()

    const region = userSettings?.region ?? "none"
    const loading = isLoading || status === "loading"

    const handleChange = (event: SelectChangeEvent<string>) => {
        mutate({
            ...userSettings,
            region: event.target.value as string
        })
    }

    return (
        <FormControl
            fullWidth
            disabled={loading}
            variant="filled">
            <InputLabel htmlFor="region-select">
                {t.profile["choose-region"]}
            </InputLabel>
            <Select
                inputProps={{
                    id: "region-select",
                    name: "region"
                }}
                label={t.profile["choose-region"]}
                onChange={handleChange}
                value={regions && region}>
                <MenuItem
                    aria-label={t["regional-content"]["no-region"]}
                    value="none">
                    {t["regional-content"]["no-region"]}
                </MenuItem>
                {status === "loading"
                    ? null
                    : regions?.map(currentRegion => (
                          <MenuItem
                              key={currentRegion}
                              disabled={
                                  !(enabled?.includes(currentRegion) ?? true)
                              }
                              value={currentRegion}>
                              {currentRegion}
                          </MenuItem>
                      ))}
            </Select>
        </FormControl>
    )
}
