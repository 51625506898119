import Megamenu from "components/Megamenu/Megamenu"
import { general } from "config/config"
import { useToggleOnScroll } from "hooks/useScrollSpy"
import Profile from "modules/profile/Profile"
import * as React from "react"

import AppBar from "@mui/material/AppBar"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"

import Favorites from "./Favorites"
import Feedback from "./Feedback"
import Logo from "./Logo"
import Notifications from "./Notifications"
import SearchField from "./SearchField"
import Settings from "./Settings"

export default function Header() {
    const show = useToggleOnScroll()

    return (
        <StyledAppBar
            elevation={0}
            position="sticky">
            <StyledContainer
                container
                alignItems="center">
                <Megamenu />
                <Grid
                    item
                    className="polyfill-logo"
                    xs>
                    <Logo />
                </Grid>
                <StyledSearchGrid
                    item
                    xs={12}
                    sm={6}
                    show={show.toString()}>
                    <SearchField />
                </StyledSearchGrid>
                <Grid item>
                    <Feedback />
                </Grid>
                <Grid item>
                    <Favorites />
                </Grid>
                {general.enableNotifications ? (
                    <Grid item>
                        <Notifications />
                    </Grid>
                ) : null}

                <Settings />
                <Grid item>
                    <Profile />
                </Grid>
            </StyledContainer>
        </StyledAppBar>
    )
}

export function HeaderSimple() {
    return (
        <AppBar
            elevation={0}
            className="classes.hideOnPrint"
            position="sticky">
            <StyledContainer
                container
                alignItems="center">
                <Grid
                    item
                    className="polyfill-logo"
                    xs
                    container>
                    <Logo simple />
                </Grid>
            </StyledContainer>
        </AppBar>
    )
}

const StyledAppBar = styled(AppBar)(() => ({
    "@media print": {
        display: "none !important"
    }
}))

const StyledContainer = styled(Grid)(({ theme }) => {
    const { contentMax, leftAside, rightAside } = theme.constants.width
    const maxWidth = contentMax + leftAside + rightAside
    return {
        maxWidth: `min(100vw, ${maxWidth}px)`,

        minHeight: theme.constants.height.header,
        padding: theme.spacing(1),
        gap: "8px 6px",
        [theme.breakpoints.up("sm")]: {
            gap: "8px",
            margin: "0 auto",
            padding: theme.spacing(0, 1, 0, 0)
        },
        "& .MuiGrid-container": {
            [theme.breakpoints.up("sm")]: {
                flexWrap: "nowrap"
            }
        }
    }
})

const StyledSearchGrid = styled(Grid)<{ show: string }>(({ theme, show }) => ({
    order: 1,
    display: show === "true" ? "block" : "none",
    [theme.breakpoints.up("sm")]: {
        paddingRight: 10,
        display: "block",
        order: 0
    }
}))
