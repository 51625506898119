import { auth } from "config/config"

import { azureAdConfig } from "./azureAdConfig"
import { identityServerConfig } from "./identityServerConfig"
import { keycloakConfig } from "./keyCloakConfig"

export type AccessTokenResponseType = {
    access_token: string
    expires_in: number
    token_type: string
}

export type AuthConfigType = {
    provider: any
    accessTokenUrl: string
    editProfileUrl: string
    endSessionUrl: string

    getServerSideAccessToken: () => Promise<AccessTokenResponseType>
}

function getCurrentAuthConfig(): AuthConfigType {
    switch (auth.provider) {
        case "keycloak":
            return keycloakConfig
        case "azure-ad-b2c":
            return azureAdConfig
        case "identity-server4":
            return identityServerConfig
        default:
            throw new Error("Unknown auth provider...")
    }
}

const currentConfig = getCurrentAuthConfig()

export { currentConfig, azureAdConfig, identityServerConfig, keycloakConfig }
