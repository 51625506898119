import Link from "components/Link"
import { t } from "locales"
import * as React from "react"

import CloseIcon from "@mui/icons-material/Close"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IMegamenuNode } from "data/contentData/api/content"

interface IMegamenuTopicsProps {
    volume?: IMegamenuNode
    onCloseClick: () => void
}

export default function MegamenuTopics({
    volume,
    onCloseClick
}: IMegamenuTopicsProps) {
    if (!volume) {
        return (
            <Grid
                container
                item
                justifyContent="center">
                <Typography>{t["loading-please-wait"]}</Typography>
            </Grid>
        )
    }

    if (!volume.nodes) {
        return (
            <Grid
                container
                item
                justifyContent="center">
                <Typography>{t["megamenu"]["no-topics-found"]}</Typography>
            </Grid>
        )
    }

    const topics =
        volume.viewType === "alphabetic" && volume.nodes
            ? Object.values(
                  volume.nodes!.reduce<Record<string, IMegamenuNode>>(
                      (acc, node) => {
                          const letter = node.name![0].toUpperCase()
                          if (acc[letter]) {
                              acc[letter].nodes?.push(node)
                          } else {
                              acc[letter] = {
                                  name: letter,
                                  nodes: [node],
                                  url: ""
                              }
                          }
                          return acc
                      },
                      {}
                  )
              )
            : volume.nodes

    return (
        <>
            <Topics>
                {topics.map(topicGroup => (
                    <li key={`${topicGroup.name}-${topicGroup.url}`}>
                        <TopicGroup>
                            <li>
                                <TopicGroupHeading variant="body1">
                                    {topicGroup.name}
                                </TopicGroupHeading>
                            </li>
                            {topicGroup.nodes?.map(topic => (
                                <ListItem
                                    key={topic.url}
                                    dense
                                    component="li">
                                    <Link
                                        noWrap
                                        title={topic.name}
                                        color="textPrimary"
                                        href={topic.url}
                                        onClick={onCloseClick}>
                                        {topic.name}
                                    </Link>
                                </ListItem>
                            ))}
                        </TopicGroup>
                    </li>
                ))}
            </Topics>
            <CloseButton
                aria-label={t.megamenu.close}
                color="default"
                onClick={onCloseClick}
                size="large">
                <CloseIcon />
            </CloseButton>
        </>
    )
}

const Topics = styled("ul")(() => ({
    columnCount: 3,
    columnGap: 0,
    listStyle: "none",
    margin: 0,
    padding: 0,
    width: "100%"
}))

const TopicGroup = styled("ul")(({ theme }) => ({
    breakInside: "avoid",
    display: "inline-block",
    listStyle: "none",
    marginBottom: theme.spacing(2),
    padding: 0,
    pageBreakInside: "avoid",
    width: "100%"
}))

const TopicGroupHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    padding: theme.spacing(0, 2)
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2)
}))
