import { useRouter } from "next/router"
import * as React from "react"

/** Returns the path, without hash and query */
export default function usePath() {
    const router = useRouter()
    return React.useMemo(
        () => new URL(router.asPath, "http://n").pathname,
        [router.asPath]
    )
}
