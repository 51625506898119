import Icon from "components/Icon"
import useUser from "hooks/useUser"
import * as React from "react"

import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"

import { isAdmin } from "lib/auth"

export const Settings: React.FC = () => {
    const { user } = useUser()

    if (!isAdmin(user?.roles)) return null

    return (
        <Grid item>
            <Tooltip title="Settings">
                <IconButton
                    color="inherit"
                    aria-label="Setting"
                    href="/admin"
                    sx={{ p: 0.5 }}
                    size="large">
                    <Icon name="settings" />
                </IconButton>
            </Tooltip>
        </Grid>
    )
}

export default Settings
