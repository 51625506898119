import { OptionsType } from "../@types/shared.types"

/**
 * Checks if a request should be fetched in a hook that uses react query. Merges optional enable parameters from argument.
 *
 * @param {boolean} enabled Custom contitional
 * @param {OptionsType<T>} options React query options object, might contain conditional from component
 * @return {boolean} Boolean if the query should be executed
 */
export default function isEnabled<T>(
    enabled: boolean,
    options?: OptionsType<T>
) {
    if (!enabled) {
        return false
    }
    if (typeof options?.enabled === "undefined") {
        return enabled
    } else {
        return enabled && options.enabled
    }
}
