import { PortableTextTypeComponentProps } from "@portabletext/react"
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
} from "@reach/disclosure"
import { useSemiControlledExpand } from "hooks/useSemiControlledExpand"
import * as React from "react"

import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
// eslint-disable-next-line no-restricted-imports
import { Palette } from "@mui/material/styles/createPalette"

import PortableText from "../../PortableText"
import CollapsibleList from "./CollapsibleList"

export interface NoteBaseProps {
    style: keyof Palette["notes"] | "collapsible" | "collapsible-list"
    title: string | JSX.Element
    blockContent?: string
    collapsed?: boolean
    id?: string
    children: React.ReactNode
    buttonRef?: React.Ref<HTMLButtonElement>
}

export default function Note({
    value
}: PortableTextTypeComponentProps<NoteBaseProps>) {
    const { style, id, collapsed, title, blockContent } = value
    const { open, onChange } = useSemiControlledExpand(
        id,
        collapsed ?? value.collapsed ?? false
    )

    const _title =
        typeof title === "string" ? (
            <PortableText value={JSON.parse(title)} />
        ) : (
            title
        )

    const children = blockContent ? (
        <PortableText
            value={JSON.parse(blockContent)}
            wrapImgWithGalleryLink
        />
    ) : null

    if (style === "collapsible-list") {
        return (
            <CollapsibleList
                collapsed={collapsed}
                title={_title}>
                {children}
            </CollapsibleList>
        )
    }

    return (
        <Disclosure
            defaultOpen={false}
            open={open}
            onChange={onChange}>
            <Summary
                notestyle={style}
                id={id}>
                <Typography variant="h4">{_title}</Typography>
            </Summary>
            <Panel notestyle={style}>{children}</Panel>
        </Disclosure>
    )
}

const Summary = styled(DisclosureButton)<{ notestyle: string }>(
    ({ notestyle, theme }) => {
        const { breakpoints, constants, palette, spacing } = theme

        const { summary, border } =
            palette.notes[notestyle as keyof Palette["notes"]]

        return {
            scrollMarginTop: `${
                constants.height.header + constants.height.headerXSExtra + 16
            }px`,
            [breakpoints.up("sm")]: {
                scrollMarginTop: `${constants.height.header + 16}`
            },
            [breakpoints.up("md")]: {
                scrollMarginTop: `${
                    constants.height.header + constants.height.breadcrumbs + 16
                }px`
            } as any,
            position: "relative",
            display: "flex",
            width: "100%",
            minHeight: 48,
            boxShadow: "none",
            padding: spacing(1, 2, 1.25),
            marginTop: spacing(2),

            cursor: "pointer",
            textAlign: "left",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: summary.main,
            border: border && `1px solid ${border}`,

            "&::after": {
                fontSize: "0.8rem",
                marginRight: "0.25rem",
                transform: "translateY(-0.125rem) rotate(90deg)",
                transition: "150ms cubic-bezier(0.4, 0, 0.2, 1)",
                // https://github.com/mui-org/material-ui/issues/14153#issuecomment-453605145
                content: '"❯"',
                "@media print": {
                    display: "none !important"
                }
            },
            '&[data-state="open"]::after': {
                transform: "rotate(270deg)"
            },
            "@media print": {
                background: "transparent",
                color: "black !important"
            },
            // Headers with BlockContent have a p tag as a child
            "& > p": {
                margin: "0 !important"
            },
            "& > h4, > h4 > p, > span, > span > p": {
                margin: "0 !important",
                fontWeight: 700,
                fontSize: "1rem"
            }
        }
    }
)

const Panel = styled(DisclosurePanel)<{ notestyle: string }>(
    ({ notestyle, theme }) => {
        const { palette, spacing } = theme
        const { details, border } =
            palette.notes[notestyle as keyof Palette["notes"]]
        return {
            backgroundColor: details,
            borderRight: border && `1px solid ${border}`,
            borderBottom: border && `1px solid ${border}`,
            borderLeft: border && `1px solid ${border}`,
            padding: spacing(2),
            marginBottom: spacing(2)
        }
    }
)
